export const Service = {
	namespaced: "Medication",
	api: {
		// 获取药盒所有信息
		GetMedicationInfo: () => ({
			url: "/webappreact/dy!getMyDeviceList.action?openId="+localStorage.getItem("openId"),
			version: "",
			method: "GET"
		}),
		// 获取药仓信息
		GetMedicationDrugBox: ({ id }) => ({
			url: `/webappreact/dy!getAllDrugboxMs.action?deviceId=${ id }`+"&openId="+localStorage.getItem("openId"),
			version: "",
			method: "GET"
		}),
		// 暂停药草
		HandleStopMedicationBox: ({ infoId }) => ({
			url: `/webappreact/dy!setDrugStop.action?infoId=${ infoId }`+"&openId="+localStorage.getItem("openId"),
			version: "",
			method: "GET"
		}),
		// 开启药草
		HandleStartMedicationBox: ({ infoId }) => ({
			url: `/webappreact/dy!setDrugStart.action?infoId=${ infoId }`+"&openId="+localStorage.getItem("openId"),
			version: "",
			method: "GET"
		}),
		CreateDevice: () => ({
			url: `/webappreact/dy!member_device.action`,
			version: "",
			method: "POST"
		}),
		//搜药品
		SearchMedication: () => ({
			url: `/webappreact/dy!getListDrug.action`,
			version: "",
			method: "POST"
		}),

		myupdatedeviceargs:()=>({
			url: `/webappreact/dy!myupdatedeviceargs.action`+"&openId="+localStorage.getItem("openId"),
			version: "",
			method: "POST"
		}),

		// 更新设备
		UpdateDeviceInfo: () => ({
			url: `/webappreact/dy!saveDeviceTixing.action`+"&openId="+localStorage.getItem("openId"),
			version: "",
			method: "POST"
		}),
		// 就诊记录
		GetDiagnosisList: ({ deviceId }) => ({
			url: `/webappreact/dy!getDiagnosisList.action?deviceId=${ deviceId } `+"&openId="+localStorage.getItem("openId"),
			version: "",
			method: "GET"
		}),
		// 就诊记录
		GetDiagnosis: ({ equal__id, deviceId }) => ({
			url: `/webappreact/dy!getDiagnosisList.action?deviceId=${ deviceId }&equal__id=${ equal__id }`+"&openId="+localStorage.getItem("openId"),
			version: "",
			method: "GET"
		}),
		// 添加就诊记录
		CreateDiagnosis: () => ({
			url: `/webappreact/dy!saveDiagnosis.action`+"&openId="+localStorage.getItem("openId"),
			version: "",
			method: "POST"
		}),
		RemoveDiagnosis: ({ id }) => ({
			url: `/webappreact/dy!delDiagnosis.action?id=${ id }`+"&openId="+localStorage.getItem("openId"),
			version: "",
			method: "POST"
		}),
		SaveWareHouse: () => ({
			url: `/webappreact/dy!saveDrugBoxSet.action`,
			version: "",
			method: "POST"
		}),
		GetNoUseDrugList: ({ deviceId, startdate, enddate,select_startdate }) => ({
			url: `/webappreact/dy!getNoUseDrugList.action?deviceId=${ deviceId }&startdate=${ startdate }&enddate=${ enddate }&select_startdate=${ select_startdate }`,
			version: "",
			method: "GET"
		}),

		//获取未服用记录
		GetNoUseDrugDetailList: ({ deviceId, startdate }) => ({
			url: `/webappreact/dy!getNoUseDrugDetailList.action?deviceId=${ deviceId }&startdate=${ startdate }`,
			version: "",
			method: "GET"
		}),

		//获取全部服用记录
		GetUseDrugDetailList: ({ deviceId, startdate }) => ({
			url: `/webappreact/dy!getUseDrugDetailList.action?deviceId=${ deviceId }&startdate=${ startdate }`,
			version: "",
			method: "GET"
		}),

		setDrugUse: ({ id }) => ({
			url: `/webappreact/dy!setDrugUse.action?&id=${ id }`,
			version: "",
			method: "GET"
		}),

		GetUseDrugList: ({ id }) => ({
			url: `/webappreact/dy!getUseDrugList.action?deviceId=${ id }`,
			version: "",
			method: "GET"
		}),
		GetWareHouseDetail: () => ({
			url: `/webappreact/dy!getDrugboxMs.action`,
			version: "",
			method: "POST"
		}),
		GetPrescription: ({ deviceId, equal__id }) => ({
			url: `/webappreact/dy!getPrescription.action?deviceId=${ deviceId }&equal__id=${ equal__id }`,
			version: "",
			method: "GET"
		}),
		GetRecordByMedication: ({ deviceId = "", startdate1 = "", endate1 = "", drugsId }) => ({
			url: `/webappreact/dy!getDrugUseInfoList.action?deviceId=${ deviceId }&startdate=${ startdate1 }&enddate=${ endate1 }&drugsId=${ drugsId }`,
			// url: `/webappreact/dy!getDrugUseInfoList.action?deviceId=32&startdate1&endate1&drugsId=275247`,
			version: "",
			method: "GET"
		}),
		GetNowUseDrugList: ({ deviceId }) => ({
			url: `/webappreact/dy!getNowUseDrugList.action?deviceId=${ deviceId }`,
			version: "",
			method: "GET"
		}),
		RemoveDevice: ({ deviceId }) => ({
			url: `/webappreact/dy!jieBangDingDevice.action?deviceId=${deviceId}`,
			version: "",
			method: "GET"
		}),

		RemoveDrug: ({ infoId }) => ({
			url: `/webappreact/dy!delDrug.action?infoId=${infoId}`+"&openId="+localStorage.getItem("openId"),
			version: "",
			method: "GET"
		}),

		UpdateVersion: ({ deviceIdCode }) => ({
			url: `/webappreact/dy!updateDeviceVersion.action?deviceIdCode=${deviceIdCode}`+"&openId="+localStorage.getItem("openId"),
			version: "",
			method: "GET"
		}),
		
		CheckedMeta: (deviceId) => ({
			url: `/webappreact/dy!isBangDingDevice.action?deviceId=${ deviceId }`+"&openId="+localStorage.getItem("openId"),
			version: "",
			method: "GET"
		}),
		
		MetaBind: ({ deviceId, openId, nicheng = "" }) => ({
			url: `/webappreact/dy!friendDevice.action?deviceId=${ deviceId }&openId=${ openId }&nicheng=${ nicheng }`,
			version: "",
			method: "GET"
		})
		
	}
}


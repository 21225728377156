import { fromJS } from "immutable"
import Service from "@/service"

const SAVE_WARE_HOUSE_SETTING = "SAVE_WARE_HOUSE_SETTING"
const SAVE_WARE_HOUSE_SEARCH_WORD = "SAVE_WARE_HOUSE_SEARCH_WORD"
const SAVE_WARE_HOUSE_MEDICATION_INFO = "SAVE_WARE_HOUSE_MEDICATION_INFO"
const SAVE_WARE_HOUSE_FORMDATA = "SAVE_WARE_HOUSE_FORMDATA"
const SAVE_WARE_HOUSE_FILESDATA = "SAVE_WARE_HOUSE_FILESDATA"
const CLEAR_WARE_HOUSE_SETTING = "CLEAR_WARE_HOUSE_SETTING"
const SAVE_VOICE_ID_ACTION = "SAVE_VOICE_ID_ACTION"

const StaticStore = {
	searchWord: "",
	medicationInfo: {
		specifications: "- -",
		drugBrand: "- -",
		id: ""
	},
	formData: {
		deviceId: "",
		drugsNumId: "",
		colorId: "",
		colorId2: "",
		drugsAppearance: "",
		drugsId: "",
		timeIds: "",
		remarks: "",
		myuseMethod: "",
		intervalDay: "",
		dosage: "",
		photoIds: "",
		drugsSum: "",
	},
	filesData: [],
	medfilesData: [],
	voiceId: "8"
}

let WarehouseStore = fromJS(StaticStore)

export const reducer = (state = WarehouseStore, action) => {
	switch (action.type) {
		case SAVE_WARE_HOUSE_SEARCH_WORD:
			return state.set("searchWord", fromJS(action.payload))
		case SAVE_WARE_HOUSE_MEDICATION_INFO:
			return state.set("medicationInfo", fromJS(action.payload))
		case SAVE_WARE_HOUSE_FORMDATA:
			return state.set("formData", fromJS(action.payload))
		case CLEAR_WARE_HOUSE_SETTING:
			return state.merge({ ...StaticStore })
		case SAVE_WARE_HOUSE_FILESDATA:
			return state.set("filesData", fromJS(action.payload))
		case SAVE_VOICE_ID_ACTION:
			return state.set("voiceId", action.payload)
		default:
			return state
	}
}
// ospyHv08IB5HT1HbWoCuqH0sxt8k
export const $GetUserInfo = (openId = "", code = "") => async dispatch => {
	const { status, data } = await Service["User/GetUserInfo"]({
		openid: openId,
		code
	})
	
	const { WxMpUser = {}, member = {} } = data
	
	dispatch({
		type: LOGIN_ACTION,
		payload: {
			userInfo: {
				WxMpUser,
				member
			},
			isAuth: status === 20000
		}
	})
}

export const $SaveSearchWord = (payload) => ({
	type: SAVE_WARE_HOUSE_SEARCH_WORD,
	payload
})
export const $SaveMedicationInfo = (payload) => ({
	type: SAVE_WARE_HOUSE_MEDICATION_INFO,
	payload
})
export const $SaveFormData = (payload) => ({
	type: SAVE_WARE_HOUSE_FORMDATA,
	payload
})
export const $SaveFilesData = (payload) => ({
	type: SAVE_WARE_HOUSE_FILESDATA,
	payload
})
export const $SaveVoiceId = (payload) => ({
	type: SAVE_VOICE_ID_ACTION,
	payload
})
export const $ClearAllSetting = () => ({ type: CLEAR_WARE_HOUSE_SETTING })

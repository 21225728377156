import axios from "axios"
import { Toast } from "antd-mobile"
import store from "./../store"
import { $GetUserInfo } from "@/store/Modules/UserModule"

export const Headers = {
	"Accept": "application/json",
	"Content-Type": "application/json;charset=UTF-8"
}

const request = axios.create({
	 //  baseURL: "http://10.20.224.18/" ,  //process.env.REACT_APP_BASE_API
	//  baseURL: "http://127.0.0.1/" ,  //process.env.REACT_APP_BASE_API
	 baseURL: "https://hsbc.mdhcare.cn/item-backend",
	 // baseURL: "http://yaohe.xiangshiinfo.com/yh",
	  //  baseURL: process.env.REACT_APP_BASE_API,
	headers: Headers,
	timeout: 60000,
	timeoutErrorMessage: "网络请求超时",
	// transformRequest: [(data = {}, headers) => {
	// 	const { uploadfiles } = data
	// 	if (uploadfiles) {
	// 		const RequestForm = new FormData()
	// 		RequestForm.append("uploadfiles", uploadfiles)
	// 		return RequestForm
	// 	} else {
	// 		console.log("uploadfiles", uploadfiles, qs.parse(data))
	// 		// return qs.stringify(data)
	// 		return qs.parse(data)
	// 	}
	// }]
	// withCredentials: true
});


// 请求拦截
request.interceptors.request.use(
	config => {
		//设置请求头
		config.headers = {
			...config.headers,
			...Headers
		}
		 
		const openId = store.getState().getIn(["User", "openId"])
		if (openId) {
			config.headers.openId = openId
		}
		
		let authorization = localStorage.getItem("authorization")
		if (authorization) {
			config.headers.authorization = authorization
		}
		
		const { uploadfiles } = (config.data || {})
		if (uploadfiles) {
			config.headers["Content-Type"] = "application/x-www-form-urlencoded"
			const RequestForm = new FormData()
			RequestForm.append("uploadfiles", uploadfiles)
			config.data = RequestForm
		}
		// console.log("--config--", config)
		return config
	},
	error => {
		console.log(error)
		return Promise.reject(error)
	}
)

//响应拦截
request.interceptors.response.use(
	({ data, headers }) => {
		const { status } = data
		if (status && status !== 20000 && status !== 304) {
			Toast.info(data.message, 3)
		} else if (status === 302) {
			Toast.info("获取信息错误")
			if(localStorage.getItem("openId")){
				$GetUserInfo({
					openId: localStorage.getItem("openId")
				})
			}
		} else if (status === 304) {
			localStorage.removeItem('openId')
			window.location.replace('/user/register')
		}
		return data
	},
	error => {
		// 获取状态码
		// const { status } = error.response
		// if (status && status === 1015) {
		// 	message.error('请重新登录')
		// 	//清楚token
		// 	localStorage.removeItem('token')
		// 	//跳转到登录页面
		// 	// router.push('/login')
		// }
		return Promise.reject(error)
	}
)

export default request

import React, { useEffect } from "react"
import routers from "@/router"
import { renderRoutes } from "react-router-config"
import { connect, Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import store from "@/store"
import { getQueryVariable } from "@/utils/helper"
import { $GetUserInfo } from "@/store/Modules/UserModule"

const Container = connect(state => {}, { $GetUserInfo })(({ $GetUserInfo }) => {
	useEffect(() => {
		const openId = getQueryVariable("openId") || localStorage.getItem("openId")
		if(openId) {
			localStorage.setItem("openId", openId)
			$GetUserInfo({
				openId
			})
		}
	}, [])
	
	return (
		<>
			{ renderRoutes(routers) }
		</>
	)
})

function App ({ ...props }) {
	return (
		<>
			<Provider store={ store }>
				<BrowserRouter>
					<Container { ...props } />
				</BrowserRouter>
			</Provider>
		</>
	)
}

export default App

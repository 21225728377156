import React, { lazy, Suspense } from "react"
import { Redirect } from "react-router-dom"
import BaseLayout from "../layout/BaseLayout"
import TabBarLayout from "../layout/TabBarLayout"

const GetLayout = Component => props => (
	<Suspense fallback={ null }>
		<Component { ...props } />
	</Suspense>
)

// 服药提醒
const MedicationReminder = lazy(() => import("../pages/Medication/Reminder/index"))
// 健康知识
const HealthKnowledge = lazy(() => import("../pages/Health/Knowledge"))
// 药盒编辑、创建
const MedicationManageCreate = lazy(() => import("../pages/Medication/Manage/Create"))
const MedicationManageEdit = lazy(() => import("../pages/Medication/Manage/Edit"))
// 记录
const MedicationRecord = lazy(() => import("../pages/Medication/Record/index"))
// 药品详情
const MedicationDetail = lazy(() => import("../pages/Medication/Detail"))
// 药盒管理
const MedicationWarehouseCreate = lazy(() => import("../pages/Medication/Warehouse/Create"))
const MedicationWarehouseEdit = lazy(() => import("../pages/Medication/Warehouse/Edit"))
const MedicationWarehouseEdit2 = lazy(() => import("../pages/Medication/Warehouse/Edit2"))

// 用户注册
const UserRegister = lazy(() => import("../pages/User/Register"))
// 设置
const Setting = lazy(() => import("../pages/Setting/index"))
const SettingWifi = lazy(() => import("../pages/Setting/WifiSetting"))
const SettingWifiCreate = lazy(() => import("../pages/Setting/WifiSettingCreate"))
// 宣教语音
const SettingVoice = lazy(() => import("../pages/Setting/Voice/index"))
const SettingVoiceCreate = lazy(() => import("../pages/Setting/Voice/Create"))
const SettingVoiceHistory = lazy(() => import("../pages/Setting/Voice/History"))
// 服药记录
const MedicalRecords = lazy(() => import("../pages/MedicalRecords"))
const MedicalRecordCreate = lazy(() => import("../pages/MedicalRecords/Create"))
const MedicalRecordEdit = lazy(() => import("../pages/MedicalRecords/Edit"))

// 处方
const Prescription = lazy(() => import ("../pages/Prescription"))

// const TestPage = lazy(() => import("../pages/test"))

const MedicalRecordsRoutes = [
	{
		path: "/medical/records/create/:id",
		title: "添加就诊记录",
		exact: true,
		component: GetLayout(MedicalRecordCreate),
	}, 
	{
		path: "/medical/records/edit/:id/:device",
		title: "就诊记录详情",
		exact: true,
		component: GetLayout(MedicalRecordEdit),
	}, 
	{
		path: "/medical/records/list/:id",
		title: "就诊记录",
		exact: true,
		component: GetLayout(MedicalRecords),
	}
]

export default [
	{
		component: BaseLayout,
		path: "/",
		routes: [
			...MedicalRecordsRoutes,
			{
				path: "/prescription/:id",
				title: "处方",
				exact: true,
				component: GetLayout(Prescription)
			},
			{
				path: "/setting/voice/history/:id",
				title: "语音历史记录",
				exact: true,
				component: GetLayout(SettingVoiceHistory),
			}, 
			{
				path: "/setting/voice/create/:id",
				title: "宣教语音",
				exact: true,
				component: GetLayout(SettingVoiceCreate),
			}, 
			{
				path: "/setting/voice/list/:id",
				title: "语音设置",
				exact: true,
				component: GetLayout(SettingVoice),
			}, 
			{
				path: "/medication/warehouse/edit/:id",
				title: "药盒管理",
				exact: true,
				component: GetLayout(MedicationWarehouseEdit),
			},
			{
				path: "/medication/warehouse/edit2/:id",
				title: "药盒管理",
				exact: true,
				component: GetLayout(MedicationWarehouseEdit2),
			}, 
			{
				path: "/medication/warehouse/create/:id",
				title: "药盒管理",
				exact: true,
				component: GetLayout(MedicationWarehouseCreate),
			}, 
			{
				path: "/medication/manage/:id",
				title: "药盒管理",
				exact: true,
				component: GetLayout(MedicationManageEdit),
			}, 
			{
				path: "/medication/manage",
				title: "药盒管理",
				exact: true,
				component: GetLayout(MedicationManageCreate),
			}, 
			{
				path: "/medication/detail/:id/:isedit",
				title: "药品详情",
				exact: true,
				component: GetLayout(MedicationDetail),
			}, 
			{
				path: "/user/register",
				title: "用户注册",
				component: GetLayout(UserRegister),
				exact: true,
			}, 
			{
				path: "/setting/wifi/create/:id",
				title: "添加WIFI",
				component: GetLayout(SettingWifiCreate),
				exact: true,
			}, 
			{
				path: "/setting/wifi/:id",
				title: "WIFI设置",
				component: GetLayout(SettingWifi),
				exact: true,
			}, 
			{
				path: "/setting/device/:id",
				title: "设置",
				component: GetLayout(Setting),
				exact: true,
			}, 
			{
				path: "/",
				component: TabBarLayout,
				routes: [
					{
						path: "/",
						exact: true,
						render: () => <Redirect to="/medication/reminder"/>,
					}, 
					{
						path: "/medication/reminder",
						title: "用药提醒",
						exact: true,
						component: GetLayout(MedicationReminder),
					}, 
					{
						path: "/health/knowledge",
						title: "健康知识",
						show: false,
						exact: true,
						component: GetLayout(HealthKnowledge),
					}, 
					{
						path: "/medication/record/:id",
						title: "用药记录",
						exact: true,
						component: GetLayout(MedicationRecord),
					}, 
					{
						path: "/medication/record2/:id/:selectdate",
						title: "用药记录",
						exact: true,
						component: GetLayout(MedicationRecord),
					},
					{
						path: "/medication/reminder2",
						title: "用药提醒",
						exact: true,
						component: GetLayout(MedicationReminder),
					}
				]
			}
		]
	}
]

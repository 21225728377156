import React, { useEffect } from "react"
import { renderRoutes } from "react-router-config"
import AuthLayout from "./AuthLayout"
import Service from "@/service"

const BaseLayout = ({ route, history, location, ...props }) => {
	
	useEffect(() => {
		// 全局微信SDK 的config在这里初始化，后续的子页面中全部基于 wx.ready 方式调用
		const initJSSDK = async () => {
			const data = await Service["Common/GetJSSDKConfig"]()

			const {
				appId,
				nonceStr,
				timestamp,
				_signature
			} = data
			
			console.log("微信SDK初始化", data)
			wx.error(function(res){
				console.log("SKD 调用错误", res)
				// config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
			});
			
			wx.config({
				debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
				appId: appId, // 必填，公众号的唯一标识
				timestamp: timestamp, // 必填，生成签名的时间戳
				nonceStr: nonceStr, // 必填，生成签名的随机串
				signature: _signature,// 必填，签名
				jsApiList: [ "scanQRCode", "onMenuShareAppMessage" ] // 必填，需要使用的JS接口列表
			})
		}
		initJSSDK()
		return () => {}
	}, [location.pathname])
	
	return (
		<>{ renderRoutes(route.routes) }</>
	)
}

export default AuthLayout(BaseLayout)

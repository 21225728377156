import { combineReducers } from 'redux-immutable'
// import { combineReducers } from 'redux'
import { reducer as User } from './UserModule'
import { reducer as Medication } from './MedicationModule'
import { reducer as Warehouse } from './WarehouseModule'

export default combineReducers({
	User,
	Medication,
	Warehouse
})

import axiosrequest from "../utils/axios"

// 补零
export const PrefixZero = (num, n) => (Array(n).join(0) + num).slice(-n)

// 获取URL转化参数
export const GetUrlQueryParams = (url) => {
	if (!url || url === "") {
		return {}
	}
	const QueryParams = {}
	const Query = url.substring(1)
	const Vars = Query.split("&")
	for (let i = 0; i < Vars.length; i++) {
		const Pair = Vars[i].split("=")
		QueryParams[Pair[0]] = Pair[1]
	}
	return QueryParams
}

// 获取URL参数
export const getQueryVariable = (variable) => {
	
	const query = window.location.search.substring(1);
	//console.log( "window.location.search="+window.location.search+"  "+ +window.location.href +"   query="+query );
	const vars = query.split("&")
	for (var i = 0; i < vars.length; i++) {
		const pair = vars[i].split("=")
		if (pair[0] === variable) {return pair[1]}
	}
	return (false)
}

// 获取药品SCG
export const MedicationSVG = (id, color1, color2) => {
	
	const GetColor = (c) => c.replace("#", "")
 
	if (color1 && color2) {
		return axiosrequest.defaults.baseURL+`/MedicationSvgServlet?color1=${ GetColor(color1) }&color2=${ GetColor(color2) }&shapeId=${ id }`
	} else if (!color1 && color2) {
		return axiosrequest.defaults.baseURL+`/MedicationSvgServlet?color1=${ GetColor(color2) }&shapeId=${ id }`
	} else if (color1 && !color2) {
		return axiosrequest.defaults.baseURL+`/MedicationSvgServlet?color1=${ GetColor(color1) }&shapeId=${ id }`
	}
	return axiosrequest.defaults.baseURL+`/MedicationSvgServlet?color1=ffffff&color2=ffffff&shapeId=${ id }`
}

export const GetDayFromDate = (date) => {
	try {
		const arr = date.split("-")
		return arr[arr.length - 1]
	} catch (e) {
		return ""
	}
}

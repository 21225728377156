export const Service = {
	namespaced: "Setting",
	api: {
		// 获取WIFI列表
		GetWIFIList: ({ deviceId }) => ({
			url: `/webappreact/dy!getWifiList.action?deviceId=${deviceId}`,
			version: "",
			method: "GET"
		}),
		// 创建WIFI
		CreateWIFI: () => ({
			url: `/webappreact/dy!saveWifi.action`,
			version: "",
			method: "POST"
		}),
		// 获取疾病类型语音
		GetKnowledgeSpeechList: ({ id }) => ({
			url: `/webappreact/dy!getKnowledgeSpeechList.action?id=${id}`,
			version: "",
			method: "POST"
		}),
		RemoveWifi: ({ id }) => ({
			url: `/webappreact/dy!getKnowledgeSpeechList.action?id=${id}`,
			version: "",
			method: "POST"
		}),
	}
}


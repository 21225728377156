import React from 'react'
import ReactDOM from 'react-dom'
import App from './pages/App'
import './assets/style/common.less'
import 'antd/dist/antd.css'
import './utils/rem'
import 'antd-mobile/dist/antd-mobile.css'

ReactDOM.render(
	<App/>,
	document.getElementById('root')
)
export const Service = {
	namespaced: "Common",
	api: {
		// 图片上传
		UploadImage: () => ({
			url: "/webappreact/dy!uploadFile.action",
			version: "",
			method: "POST"
		}),
		GetCommonParams: ({ key }) => ({
			url: `/webappreact/dy!getParameterMs.action?name=${ key }`+"&openId="+localStorage.getItem("openId"),
			version: "",
			method: "GET"
		}),
		GetColorList: () => ({
			url: `/webappreact/dy!getParameterMs.action?name=yanse`+"&openId="+localStorage.getItem("openId"),
			version: "",
			method: "GET"
		}),
		GetShapeList: () => ({
			url: `/webappreact/dy!getParameterMs.action?name=xingzhuang`+"&openId="+localStorage.getItem("openId"),
			version: "",
			method: "GET"
		}),
		GetJiLiangList: () => ({
			url: `/webappreact/dy!getParameterMs.action?name=jiliang`+"&openId="+localStorage.getItem("openId"),
			version: "",
			method: "GET"
		}),
		GetfuyongtianshuList: () => ({
			url: `/webappreact/dy!getParameterMs.action?name=fuyongtianshu`+"&openId="+localStorage.getItem("openId"),
			version: "",
			method: "GET"
		}),
		GetTimeList: () => ({
			url: `/webappreact/dy!getParameterMs.action?name=shijiandian`+"&openId="+localStorage.getItem("openId"),
			version: "",
			method: "GET"
		}),
		GetTimeListById: ({ deviceId = "",drugBoxId="" }) => ({
			url: `/webappreact/dy!getParameterMs.action?deviceId=${ deviceId }&name=devicceshijiandian&drugBoxId=${drugBoxId}`+"&openId="+localStorage.getItem("openId"),
			version: "",
			method: "GET"
		}),
		GetJSSDKConfig: () => {
			console.log("发起JSSDK的当前路径 => ", window.location.href)
			return {
				url: `/weixinAction/dy!getWeixinJssdk.action?jsurl=${ window.location.href }`,
				version: "",
				method: "GET"
			}
		}
	}
}


// 底部导航栏
import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { renderRoutes } from "react-router-config"
import TabBarA from "@/assets/images/tabbar-a.png"
import TabBarB from "@/assets/images/tabbar-b.png"
import TabBarC from "@/assets/images/tabbar-c.png"
import TabBarAActive from "@/assets/images/tabbar-a-active.png"
import TabBarBActive from "@/assets/images/tabbar-b-active.png"
import TabBarCActive from "@/assets/images/tabbar-c-active.png"
import AuthLayout from './AuthLayout'
import { connect } from 'react-redux'

const LayoutStyle = styled.div`
	width: 100vw;
	height: 100vh;
	display:flex;
	flex-direction: column;
`

const ContainerStyle = styled.div`
	width: 100%;
	height: calc(100vh - 0.98rem);
	flex: auto;
	background-color: #F4F4F4;
	overflow-y: scroll;
`

const TabBarStyle = styled.div`
	width: 100%;
	height :0.98rem;
	display:flex;
	align-items: center;
	justify-content: space-between;
	overflow: hidden;
	background-color: #fff;
	flex: none;
	.tab-bar-item,
	.tab-bar-item-active{
		width: 33.333333%;
		height: 100%;
		display:flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		color: #999999;
	}
	.tab-bar-icon{
		width: 0.4rem;
		height: 0.4rem;
		flex: none;
		margin-bottom: 0.12rem;
		background-position: center center;
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}
	.tab-bar-item-active{
		color: #333333;
	}
`

const TabBarLayout = connect((state) => ({
	ImtDeviceList: state.getIn(["Medication", "deviceList"]),
	touchIndex: state.getIn(["Medication", "touchIndex"])
}), {})(({ ImtDeviceList = [],touchIndex, route, location }) => {
	
	const deviceList = ImtDeviceList.toJS()
	const DeviceId = deviceList[touchIndex]?.id || ""
	
	const Routes = [{
		id: 1,
		path: "/medication/reminder",
		text: "用药提醒",
		icon: TabBarA,
		hidden: false,
		selectIcon: TabBarAActive,
	}, {
		id: 2,
		path: "/health/knowledge",
		text: "健康知识",
		hidden: true,
		icon: TabBarB,
		selectIcon: TabBarBActive,
	}, {
		id: 3,
		path: `/medication/record/${DeviceId}`,
		text: "用药记录",
		icon: TabBarC,
		hidden: false,
		selectIcon: TabBarCActive,
	}]
	
	return (
		<LayoutStyle>
			<ContainerStyle>
				{ renderRoutes(route.routes) }
			</ContainerStyle>
			<TabBarStyle>
				{
					Routes.map(item => {
						return item.hidden ? null : <Link className={ location.pathname === item.path ? "tab-bar-item-active" : "tab-bar-item" }
						      to={ item.path }
						      key={ item.id }>
							<div className="tab-bar-icon" style={ {
								backgroundImage: `url(${ location.pathname === item.path ? item.selectIcon : item.icon })`
							} }/>
							{ item.text }
						</Link>
					})
				}
			</TabBarStyle>
		</LayoutStyle>
	)
})

export default AuthLayout(TabBarLayout)
